import anchor from "../assets/image/anchor.png";
import bar from "../assets/image/bar.png";
import gym from "../assets/image/gym.png";
import restaurant from "../assets/image/restaurant.png";
import hotelF from "../assets/image/hotelF.jpg";

const Facility = () => {
  return (
    <div className="facility">
      <div className="facility__header">
        <h3>HOTEL FACILTIES</h3>
        <img src={anchor} alt="" />
      </div>

      <div className="facility__main">
        <div className="facility__main__header">
          <div className="facility__main__header__item left active">
            <img src={restaurant} alt="" />
            RESTAURANT
          </div>
          <div className="facility__main__header__item">
            <img src={bar} alt="" />
            BAR
          </div>
          <div className="facility__main__header__item right">
            <img src={gym} alt="" />
            GYM
          </div>
        </div>

        <div className="facility__main__desc">
          <div className="facility__main__desc__left">
            <img src={hotelF} alt="" />
          </div>
          <div className="facility__main__desc__right">
            <h5>THE WORLD CLASS</h5>
            <h4>RESTAURANT & BANQUETS</h4>
            <p>
              Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia.
              Phasellus accumsan urna vitae molestie interdum. Nam sed placerat
              libero, non eleifend dolor.
            </p>
            <p>
              Cras ac justo et augue suscipit euismod vel eget lectus. Proin
              vehicula nunc arcu, pulvinar accumsan nulla porta vel. Vivamus
              malesuada vitae sem ac pellentesque.
            </p>

            <span>SERVICE HOURS; 19.00-22:00 </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facility;
