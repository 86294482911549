import React from "react";
import DatePicker from "react-datepicker";

const BookFloatAlt2 = ({
  arrival,
  setArrival,
  departure,
  setDeparture,
  rooms,
  setRooms,
  adults,
  setAdults,
  children,
  setChildren,
}) => {
  const roomNo = [1, 2, 3];
  const personsNo = [1, 2, 3, 4, 5];
  const childNo = [0, 1, 2, 3, 4, 5];

  return (
    <div className="bookFloat inBooking">
      <h3 className="bookFloat__title">
        <span>CHOOSE</span> Option
      </h3>
      <div className="bookFloat__line"></div>
      <div className="bookFloat__form">
        <div className="bookFloat__form__inputCon">
          <DatePicker
            minDate={new Date()}
            showIcon
            selected={arrival}
            onChange={(date) => setArrival(date)}
            className="bookFloat__form__inputCon__dateInput"
            placeholderText="ARRIVAL"
          />
        </div>
        <div className="bookFloat__form__inputCon">
          <DatePicker
            minDate={new Date()}
            showIcon
            selected={departure}
            onChange={(date) => setDeparture(date)}
            className="bookFloat__form__inputCon__dateInput"
            placeholderText="DEPARTURE"
          />
        </div>
        <div className="bookFloat__form__inputCon">
          <select
            name="room"
            id="room"
            value={rooms}
            onChange={(e) => setRooms(e.target.value)}
          >
            {roomNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ROOM
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <select
            name="adult"
            id="adult"
            value={adults}
            onChange={(e) => setAdults(e.target.value)}
          >
            {personsNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ADULT
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <select
            name="child"
            id="child"
            value={children}
            onChange={(e) => setChildren(e.target.value)}
          >
            {childNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ADULT
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default BookFloatAlt2;
