import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + "/api";

export const fetchRooms = (page, limit) => {
  return axios.get(`${apiURL}/room/all/${page}/${limit}`);
};

export const fetchRoom = (id) => {
  return axios.get(`${apiURL}/room/${id}`);
};

export const fetchRoomAlt = (id) => {
  return axios.get(`${apiURL}/room/alt/${id}`);
};

export const fetchRoomsBySearch = (query, page) => {
  return axios.get(`${apiURL}/room/search/${query}/${page}/10`);
};

export const sendMessage = (data) => {
  return axios.post(`${apiURL}/message/add`, data);
};

export const login = (data) => {
  return axios.post(`${apiURL}/auth/signin`, data);
};

export const signup = (data) => {
  return axios.post(`${apiURL}/auth/signup`, data);
};

export const verifyEmail = (token) => {
  return axios.put(`${apiURL}/auth/email-verify/${token}`);
};

export const forgetPassword = (data) => {
  return axios.post(`${apiURL}/auth/forget-password`, data);
};

export const resetPasswordFp = (data) => {
  return axios.put(`${apiURL}/auth/reset-password-fp`, data);
};

export const fetchUserBook = (userId, token) => {
  return axios.get(`${apiURL}/book/user/${userId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchGallary = (page, limit) => {
  return axios.get(`${apiURL}/gallary/all/${page}/${limit}`);
};

export const bookRoom = (data) => {
  return axios.post(`${apiURL}/book/add`, data);
};
