import React, { useContext, useEffect, useState } from "react";
import HeaderImg from "../components/HeaderImg";
import { Context } from "../context/MainContext";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../context/Action";
import { toast } from "react-toastify";
import { fetchUserBook } from "../api/usersApi";
import Loading from "../components/Loading";

const Account = () => {
  const { user, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const formattingOption = {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  };

  const handleLogout = () => {
    localStorage.removeItem("sokoUser");
    dispatch(userLogOut());
    navigate("/login");
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleMessageCount = async () => {
      if (user?.id && user?.roles === "user") {
        setLoading(true);
        try {
          const res = await fetchUserBook(user?.id, user?.accessToken);

          setOrders(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleMessageCount();
  }, [dispatch, user?.id, user?.accessToken, user?.roles]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <HeaderImg title="Account" />

      <div className="account__main">
        <div className="account__main__details">
          <h3>Account Details</h3>
          <div className="account__main__details__info">
            <div className="account__main__details__info__item">
              First Name: <span>{user?.firstName}</span>
            </div>
            <div className="account__main__details__info__item">
              Last Name: <span>{user?.lastName}</span>
            </div>
            <div className="account__main__details__info__item">
              Email: <span>{user?.email}</span>
            </div>
          </div>
        </div>
        <div className="account__main__logout">
          <button className="btn" onClick={handleLogout}>
            Logout
          </button>
        </div>

        <div className="account__main__order">
          <h3>Booking History</h3>

          <div className="homeDB__users__table">
            <div
              className="homeDB__users__table__body"
              style={{ minWidth: 1200 }}
            >
              <div className="homeDB__users__table__row head">
                <div
                  style={{ width: "30px" }}
                  className="homeDB__users__table__row__head"
                >
                  s/n
                </div>
                <div
                  style={{ width: "250px" }}
                  className="homeDB__users__table__row__head"
                >
                  Id
                </div>
                <div
                  style={{ width: "100px" }}
                  className="homeDB__users__table__row__head"
                >
                  Date
                </div>
                <div
                  style={{ width: "150px" }}
                  className="homeDB__users__table__row__head"
                >
                  Payment Id (Reference number)
                </div>
                <div
                  style={{ width: "100px" }}
                  className="homeDB__users__table__row__head"
                >
                  Payed
                </div>
                <div
                  style={{ width: "100px" }}
                  className="homeDB__users__table__row__head"
                >
                  Arrival
                </div>
                <div
                  style={{ width: "100px" }}
                  className="homeDB__users__table__row__head"
                >
                  Departure
                </div>
                <div
                  style={{ width: "150px" }}
                  className="homeDB__users__table__row__head"
                >
                  Number of days (Nights)
                </div>
                <div
                  style={{ width: "100px" }}
                  className="homeDB__users__table__row__head"
                >
                  Room Name
                </div>
                <div
                  style={{ width: "100px" }}
                  className="homeDB__users__table__row__head"
                >
                  Number of rooms
                </div>
                <div
                  style={{ width: "150px" }}
                  className="homeDB__users__table__row__head"
                >
                  Total Amount
                </div>
                {/* <div
              style={{ width: "100px" }}
              className="homeDB__users__table__row__head"
            ></div> */}
              </div>

              {loading ? (
                <Loading />
              ) : (
                orders?.map((order, i) => {
                  return (
                    <div key={order._id} className="homeDB__users__table__row">
                      <div
                        style={{ width: "30px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {(1 - 1) * 10 + (i + 1)}
                      </div>
                      <div
                        style={{ width: "250px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.id}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.createdAt?.slice(0, 10)}
                      </div>
                      <div
                        style={{ width: "150px" }}
                        className="homeDB__users__table__row__data"
                      >
                        <div className="homeDB__users__table__row__data__disc">
                          {order?.paymentId}
                        </div>
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.payed ? "True" : "False"}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.arrival}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.departure}
                      </div>
                      <div
                        style={{ width: "150px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.night}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.room?.name}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.roomNo}
                      </div>
                      <div
                        style={{ width: "150px" }}
                        className="homeDB__users__table__row__data"
                      >
                        {order?.totalAmount?.toLocaleString(
                          "en-NG",
                          formattingOption
                        )}
                      </div>

                      {/* <div
                    style={{ width: "100px" }}
                    className="homeDB__users__table__row__data"
                  >
                    <Link to={"/dashboard/books/" + order?.id}>
                      <button className="homeDB__users__table__row__data__btn">
                        More Details
                      </button>
                    </Link>
                  </div> */}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
