import { Link } from "react-router-dom";
import anchor from "../assets/image/anchor.png";
import RoomCard from "./RoomCard";
import { useEffect, useState } from "react";
import { fetchRooms } from "../api/usersApi";
import { toast } from "react-toastify";
import Loading from "./Loading";

function Welcome() {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page] = useState(1);

  useEffect(() => {
    const handleFetchRooms = async () => {
      setLoading(true);
      try {
        const res = await fetchRooms(page, 4);

        setRooms(res.data.rooms);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("rooms", rooms);
  return (
    <div className="welcome">
      <div className="welcome__info">
        <h2>WELCOME TO VALLEY VIEW HOTEL</h2>
        <img src={anchor} alt="" />

        <p>
          Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia.
          Phasellus accumsan urna vitae molestie interdum. Nam sed placerat
          libero, non eleifend dolor.
        </p>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="welcome__rooms">
          {rooms?.map((room) => {
            return <RoomCard key={room?.id} room={room} />;
          })}
        </div>
      )}

      <div className="welcome__btn">
        <Link to="/accomodation">
          <button className="btn">VIEW ALL ROOMS</button>
        </Link>
      </div>
    </div>
  );
}

export default Welcome;
