import SocialLinks from "./SocialLinks";

const ContactB = () => {
  return (
    <div className="contactB">
      <div className="contactB__info">
        <div className="contactB__info__item">
          <h5>CALL US</h5>
          <p>123 456 7890</p>
        </div>
        <div className="contactB__info__item">
          <h5>EMAIL US</h5>
          <p>info@hotelbooking.com</p>
        </div>
      </div>

      <SocialLinks />
    </div>
  );
};

export default ContactB;
