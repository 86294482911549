import React, { useEffect } from "react";
import Hero from "../components/Hero";
import Welcome from "../components/Welcome";
import Facility from "../components/Facility";
import ShowCase from "../components/ShowCase";
import Map from "../components/Map";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home">
      <Hero />
      <Welcome />
      <Facility />
      <ShowCase />
      <Map />
    </div>
  );
};

export default Home;
