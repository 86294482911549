import location from "../assets/image/svg/location.svg";
import logoIcon from "../assets/image/logoAlt.png";
import map from "../assets/image/map.jpg";
import { Link } from "react-router-dom";
import { covertToUrl } from "./TopBar";

const Footer = () => {
  const links = [
    "ACCOMODATION",
    "GALLERY",
    "ABOUT US",

    "OUR STAFF",

    "BOOKING",

    "CONTACTS",
    "ROOM DETAILS",
  ];

  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__main__hot">
          <Link to="/">
            <img src={logoIcon} alt="" className="footer__main__hot__logo" />
          </Link>

          <p>
            Lorem ipsum dolor sit amet, conser adipiscing <br /> elit. In
            consectetur tincidunt dolor
          </p>
          <p className="footer__main__hot__location">
            <img src={location} style={{ width: 20 }} alt="" />
            <span>
              11-13 Efon Alaye Street <br />
              Rockview Estate Dawaki - Abuja
            </span>
          </p>
        </div>
        <div className="footer__main__links">
          <h3>QUICK LINKS</h3>
          <ul className="footer__main__links__main">
            {links.map((link) => {
              return (
                <li className="footer__main__links__main__item" key={link}>
                  <Link
                    to={
                      link === "BOOKING" || link === "ROOM DETAILS"
                        ? "/" +
                          covertToUrl(link) +
                          "/b0ac27cd-76e9-4f70-bcdc-32453543556e"
                        : "/" + covertToUrl(link)
                    }
                  >
                    {link.toLowerCase()}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer__main__glo">
          <h3>WE ARE GLOBAL</h3>
          <img src={map} alt="" />
        </div>
      </div>
      <div className="footer__right">
        <p>© 2024 Valley View Hotel. All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
