import { useContext, useEffect, useState } from "react";
import HeaderImg from "../components/HeaderImg";
import { facilities } from "./About";
import BookFloatAlt2 from "../components/BookFloatAlt2";
import { Input, Textarea } from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { bookRoom, fetchRoomAlt } from "../api/usersApi";
import { formattingOption } from "../components/RoomCard";
import { Context } from "../context/MainContext";
import moment from "moment";
import { PaystackButton } from "react-paystack";
import Loading from "../components/Loading";

const Booking = () => {
  const { booking, user } = useContext(Context);

  const [room, setRoom] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [reference, setReference] = useState("");
  const { id } = useParams();
  const [arrival, setArrival] = useState(
    booking?.arrival ? booking.arrival : ""
  );
  const [departure, setDeparture] = useState(
    booking?.departure ? booking.departure : ""
  );
  const [rooms, setRooms] = useState(booking?.rooms ? booking.rooms : 1);
  const [adults, setAdults] = useState(booking?.adults ? booking.adults : 1);
  const [children, setChildren] = useState(
    booking?.children ? booking.children : 0
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [request, setRequest] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [inputError, setInputError] = useState({});

  const headerList = [
    "BOOKING INFO",
    "PERSONAL INFO",
    "PAYMENT (optional)",
    "BOOKING DONE",
  ];

  const validate = () => {
    let phoneError = "";
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";

    if (!phone) {
      phoneError = "Phone is required, minimum 2 characters";
    }
    if (!firstName) {
      firstNameError = "first name is required, minimum 2 characters";
    }
    if (!lastName) {
      lastNameError = "last name is required, minimum 2 characters";
    }
    if (!email) {
      emailError = "email is required, minimum 2 characters";
    }

    if (phoneError || lastNameError || firstNameError || emailError) {
      setInputError((curr) => {
        return {
          ...curr,
          phone: phoneError,
          lastName: lastNameError,
          firstName: firstNameError,
          email: emailError,
        };
      });
      return false;
    }
    return true;
  };

  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    if (reference?.status === "success") {
      setReference(reference?.reference);
    }
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    toast.error("Payment Cancelled");
  };

  const handleCalcDate = () => {
    if (arrival && departure) {
      var a = moment(departure);
      var b = moment(arrival);

      return a.diff(b, "days");
    }

    return 0;
  };

  const handleMoveToStep2 = () => {
    if (arrival && departure) {
      var a = moment(departure);
      var b = moment(arrival);

      if (a.diff(b, "days") > 0) {
        setStep(2);
      } else {
        toast.warning("Select Correct Date");
      }
    } else {
      toast.warning("Select Correct Date");
    }
  };

  const handleMoveToStep3 = () => {
    const checkValidate = validate();
    if (checkValidate) {
      setStep(3);
    } else {
      toast.warning("Fill required fields");
    }
  };

  const config = {
    reference: new Date().getTime().toString(),
    email,
    amount:
      (handleCalcDate() * room?.room?.price * rooms * 0.1 +
        handleCalcDate() * room?.room?.price * rooms) *
      100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_d8774471f5a757ea685896869c6b9f093744f892",
  };

  const componentProps = {
    ...config,
    text: "Pay Now",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const handleBooking = async () => {
    setLoadingMain(true);

    let data = {
      arrival: moment(arrival).format("MMM Do YYYY"),
      departure: moment(departure).format("MMM Do YYYY"),
      night: handleCalcDate(),
      roomId: room?.room?.id,
      userId: user?.id,
      roomNo: rooms,
      adult: adults,
      child: children,
      totalAmount:
        handleCalcDate() * room?.room?.price * 0.1 +
        handleCalcDate() * room?.room?.price,
      payed: reference ? true : false,
      paymentId: reference,
      firstName,
      lastName,
      email,
      phone,
      request,
    };

    try {
      const res = await bookRoom(data);

      setStep(4);
      setBookingId(res.data.id);
      console.log("res.data", res.data);
      setLoadingMain(false);
    } catch (err) {
      setLoadingMain(false);
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      toast.error(message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const handleFetchRoom = async () => {
      setLoading(true);
      try {
        const res = await fetchRoomAlt(id);

        setRoom(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchRoom();
  }, [id]);

  return (
    <div className="booking">
      <HeaderImg title="BOOKING" alt={true} />

      {loading ? (
        <Loading />
      ) : room?.room?.name ? (
        <div className="booking__main">
          <div className="booking__main__header">
            {headerList.map((item, i) => {
              return (
                <div
                  key={item}
                  className={
                    step === i + 1
                      ? "booking__main__header__item active"
                      : "booking__main__header__item"
                  }
                >
                  <span
                    className={
                      step === i + 1
                        ? "booking__main__header__item__num active"
                        : "booking__main__header__item__num"
                    }
                  >
                    {i + 1}
                  </span>
                  <p>{item}</p>
                </div>
              );
            })}
          </div>

          {step === 1 && (
            <div className="booking__main__room">
              <div className="booking__main__room__main">
                <div className="booking__main__room__main__img">
                  <img src={room?.room?.image} alt="" />
                </div>
                <div className="booking__main__room__main__info">
                  <h4>
                    {room?.room?.name} (
                    {room?.room?.price?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}{" "}
                    <span>/night</span>)
                  </h4>

                  <div className="room__main__bottom__left__fac__list">
                    {facilities.map((item) => {
                      if (room?.facility?.[item.slug]) {
                        return (
                          <div
                            className="room__main__bottom__left__fac__list__item"
                            key={item.name}
                          >
                            <img src={item.img} alt="" />
                            <p>{item.name}</p>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>

              <div className="accomodation__main__filterCon">
                <BookFloatAlt2
                  arrival={arrival}
                  setArrival={setArrival}
                  departure={departure}
                  setDeparture={setDeparture}
                  setChildren={setChildren}
                  children={children}
                  adults={adults}
                  setAdults={setAdults}
                  rooms={rooms}
                  setRooms={setRooms}
                />
              </div>

              <div className="booking__main__room__bookInfo">
                <p className="booking__main__room__bookInfo__desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
                  atque vel at repellat, ducimus hic laboriosam vitae architecto
                  cumque repudiandae eaque consequatur eveniet quas dolorum,
                  voluptatum exercitationem rerum recusandae dignissimos.
                </p>
                <div className="booking__main__room__bookInfo__right">
                  <table class="booking__main__room__bookInfo__right__table">
                    <tbody>
                      <tr class="">
                        <td class="">
                          <span class="">{rooms} Room</span> <br />
                          {adults} Adult &amp; {children} child
                        </td>
                        <td class="">
                          <span class="">
                            {room?.room?.price?.toLocaleString(
                              "en-NG",
                              formattingOption
                            )}
                          </span>{" "}
                          <br /> rate
                        </td>
                        <td class="">
                          <span>{handleCalcDate()}</span> <br />
                          night
                        </td>
                        <td class="">
                          <span class="">
                            {(
                              handleCalcDate() *
                              room?.room?.price *
                              rooms
                            ).toLocaleString("en-NG", formattingOption)}
                          </span>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="">
                          <span class="">tax</span> <br /> 10% on booking value
                        </td>
                        <td class="" colSpan="3" style={{ textAlign: "end" }}>
                          <span class="">
                            {(
                              handleCalcDate() *
                              room?.room?.price *
                              rooms *
                              0.1
                            ).toLocaleString("en-NG", formattingOption)}
                          </span>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="">
                          <span class="">total</span>
                        </td>

                        <td class="" colSpan="3" style={{ textAlign: "end" }}>
                          <span class="">
                            {" "}
                            {(
                              handleCalcDate() *
                                room?.room?.price *
                                rooms *
                                0.1 +
                              handleCalcDate() * room?.room?.price * rooms
                            ).toLocaleString("en-NG", formattingOption)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="booking__main__btns">
                <div></div>
                <button
                  className="booking__main__btns__right"
                  onClick={handleMoveToStep2}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="booking__main__personal">
              <div className="booking__main__personal__top">
                <div className="booking__main__personal__top__inputCon">
                  <Input
                    type="text"
                    placeholder="FIRST NAME*"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={inputError.firstName ? true : false}
                  />
                </div>
                <div className="booking__main__personal__top__inputCon">
                  <Input
                    type="text"
                    placeholder="LAST NAME*"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={inputError.lastName ? true : false}
                  />
                </div>
                <div className="booking__main__personal__top__inputCon">
                  <Input
                    type="text"
                    placeholder="EMAIL*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={inputError.email ? true : false}
                  />
                </div>
                <div className="booking__main__personal__top__inputCon">
                  <Input
                    type="text"
                    placeholder="PHONE*"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    error={inputError.phone ? true : false}
                  />
                </div>
              </div>

              <div className="booking__main__personal__req">
                <Textarea
                  rows={5}
                  placeholder="ANY SPECIFIC REQUEST"
                  value={request}
                  onChange={(e) => setRequest(e.target.value)}
                />
              </div>

              <div className="booking__main__btns">
                <button
                  className="booking__main__btns__left"
                  onClick={() => setStep(1)}
                >
                  Back
                </button>
                <button
                  className="booking__main__btns__right"
                  onClick={handleMoveToStep3}
                >
                  NEXT
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="booking__main__payment">
              <div className="booking__main__payment__desc">
                <h4>Pay with Pay stack</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nihil atque modi velit molestiae, repellendus iure sint
                  possimus cumque, provident, dolorum unde laboriosam ut eius ex
                  maiores quod repudiandae aut asperiores?
                </p>
                {reference && (
                  <span>
                    Payment Successfull Proceed to complete booking ( Payment
                    Reference ID : {reference} )
                  </span>
                )}
              </div>

              <div className="booking__main__payment__pay">
                {!reference && <PaystackButton {...componentProps} />}
              </div>

              <div className="booking__main__btns">
                <button
                  className="booking__main__btns__left"
                  onClick={() => setStep(2)}
                >
                  Back
                </button>
                <button
                  className="booking__main__btns__right"
                  onClick={handleBooking}
                >
                  {loadingMain ? <Loading button={true} /> : "NEXT"}
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="booking__main__done">
              <div className="booking__main__room__bookInfo">
                <p className="booking__main__room__bookInfo__desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
                  atque vel at repellat, ducimus hic laboriosam vitae architecto
                  cumque repudiandae eaque consequatur eveniet quas dolorum,
                  voluptatum exercitationem rerum recusandae dignissimos. <br />
                  <span>
                    Your reservation was succefully submited!! <br /> Booking
                    ID: {bookingId}
                  </span>
                </p>

                <div className="booking__main__room__bookInfo__right">
                  <table class="booking__main__room__bookInfo__right__table">
                    <tbody>
                      <tr class="">
                        <td class="">
                          <span class="">{rooms} Room</span> <br />
                          {adults} Adult &amp; {children} child
                        </td>
                        <td class="">
                          <span class="">
                            {room?.room?.price?.toLocaleString(
                              "en-NG",
                              formattingOption
                            )}
                          </span>{" "}
                          <br /> rate
                        </td>
                        <td class="">
                          <span>{handleCalcDate()}</span> <br />
                          night
                        </td>
                        <td class="">
                          <span class="">
                            {(
                              handleCalcDate() *
                              room?.room?.price *
                              rooms
                            ).toLocaleString("en-NG", formattingOption)}
                          </span>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="">
                          <span class="">tax</span> <br /> 10% on booking value
                        </td>
                        <td class="" colSpan="3" style={{ textAlign: "end" }}>
                          <span class="">
                            {" "}
                            {(
                              handleCalcDate() *
                              room?.room?.price *
                              rooms *
                              0.1
                            ).toLocaleString("en-NG", formattingOption)}
                          </span>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="">
                          <span class="">total</span>
                        </td>

                        <td class="" colSpan="3" style={{ textAlign: "end" }}>
                          <span class="">
                            {" "}
                            {(
                              handleCalcDate() *
                                room?.room?.price *
                                rooms *
                                0.1 +
                              handleCalcDate() * room?.room?.price * rooms
                            ).toLocaleString("en-NG", formattingOption)}{" "}
                          </span>{" "}
                          <br />
                          {reference ? (
                            <span style={{ color: "#88d54f" }}>Paid</span>
                          ) : (
                            <span>Not Paid</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Booking;
