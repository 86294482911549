import { Link } from "react-router-dom";

export const formattingOption = {
  style: "currency",
  currency: "NGN",
  minimumFractionDigits: 0,
};

const RoomCard = ({ room }) => {
  return (
    <div className="welcome__rooms__item">
      <div className="welcome__rooms__item__imgCon">
        <img src={room?.image} alt="" />
      </div>
      <div className="welcome__rooms__item__info">
        <div className="welcome__rooms__item__info__left">
          <Link to={"/room-details/" + room?.id}>
            <h4>{room?.name}</h4>
          </Link>
          <p>
            {room?.price?.toLocaleString("en-NG", formattingOption)}/{" "}
            <span>night</span>
          </p>
        </div>
        <Link to={"/booking/" + room.id}>
          <button>Book</button>
        </Link>
      </div>

      <div className="welcome__rooms__item__desc">
        <div className="welcome__rooms__item__desc__main">
          <h4>{room?.name}</h4>
          <p>
            {room?.description?.length > 50
              ? room?.description?.slice(0, 50) + "..."
              : room?.description}
          </p>
          <div className="welcome__rooms__item__desc__main__bottom">
            <Link to={"/room-details/" + room.id}>
              <button>Veiw More Details</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
