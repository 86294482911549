import HeaderImg from "../components/HeaderImg";
import { Slide } from "react-slideshow-image";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import { fetchGallary } from "../api/usersApi";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

const Gallary = () => {
  const [pageCount, setPageCount] = useState(2);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [gallaries, setGallaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [magImg, setMagImg] = useState("");

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    const handleFetchRooms = async () => {
      setLoading(true);
      try {
        const res = await fetchGallary(page, 10);

        setGallaries(res.data.gallaries);
        setPageCount(res.data.totalPages);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gallary">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Full image"
      >
        <div className="gallary__modal">
          <div className="gallary__modal__close">
            <span onClick={() => setModalIsOpen(false)}>x</span>
          </div>
          <img src={magImg} alt="" style={{ maxHeight: "90vh" }} />
        </div>
      </Modal>
      <HeaderImg title="Gallary" alt={true} />

      <div className="gallary__main">
        {loading ? (
          <Loading />
        ) : gallaries.length > 0 ? (
          <div className="gallary__main__special">
            <div className="gallary__main__special__left">
              <Slide
                slidesToScroll={1}
                slidesToShow={1}
                arrows={true}
                infinite={true}
                pauseOnHover={false}
                duration={3000}
                indicators={true}
              >
                {gallaries?.map((item, i) => {
                  return (
                    <div key={i} className="gallary__main__special__left__item">
                      <img src={item?.image} alt="" />
                    </div>
                  );
                })}
              </Slide>
            </div>

            {/* <div className="gallary__main__special__right">
            <img src={slider} alt="" style={{ marginBottom: 20 }} />
            <img src={slider} alt="" />
          </div> */}
          </div>
        ) : (
          <div></div>
        )}

        {loading ? (
          <Loading />
        ) : (
          <div className="gallary__main__other">
            {gallaries.map((item, i) => {
              return (
                <img
                  key={i}
                  src={item?.image}
                  alt=""
                  onClick={() => {
                    setModalIsOpen(true);
                    setMagImg(item?.image);
                  }}
                />
              );
            })}
          </div>
        )}

        {pageCount > 1 && (
          <div style={{ marginTop: 40 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              onPageChange={handlePageClick}
              // pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallary;
