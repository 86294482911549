import HeaderImg from "../components/HeaderImg";
import anchor from "../assets/image/anchor.png";
import Filter from "../components/Filter";
import RoomCard, { formattingOption } from "../components/RoomCard";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { fetchRooms } from "../api/usersApi";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";

const Accomodation = () => {
  const [pageCount, setPageCount] = useState(2);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    const handleFetchRooms = async () => {
      setLoading(true);
      try {
        const res = await fetchRooms(page, 10);

        setRooms(res.data.rooms);
        setPageCount(res.data.totalPages);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="accomodation">
      <HeaderImg title="ACCOMODATION" />

      <div className="accomodation__main">
        <div className="accomodation__main__header">
          <h2>SPECIAL ROOMS</h2>
          <img src={anchor} alt="" />
        </div>
        <div className="accomodation__main__special">
          <div className="accomodation__main__special__left">
            <div className="accomodation__main__special__item big">
              <img src={rooms?.[0]?.image} alt="" />

              <div className="accomodation__main__special__item__info big">
                <div className="accomodation__main__special__item__info__left">
                  <Link to={"/room-details/" + rooms?.[0]?.id}>
                    <h4 style={{ fontSize: 21 }}>{rooms?.[0]?.name}</h4>{" "}
                  </Link>
                  <div className="accomodation__main__special__item__info__left__line"></div>
                  <p style={{ fontSize: 16 }}>
                    {rooms?.[0]?.price?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}{" "}
                    <span>/ night</span>
                  </p>
                </div>
                <Link to={"/booking/" + rooms?.[0]?.id}>
                  <button>Book</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="accomodation__main__special__right">
            <div className="accomodation__main__special__item">
              <img src={rooms?.[0]?.image} alt="" />

              <div className="accomodation__main__special__item__info">
                <div className="accomodation__main__special__item__info__left">
                  <Link to={"/room-details/" + rooms?.[0]?.id}>
                    <h4>{rooms?.[0]?.name}</h4>{" "}
                  </Link>
                  <div className="accomodation__main__special__item__info__left__line"></div>
                  <p>
                    {rooms?.[0]?.price?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}{" "}
                    <span>/ night</span>
                  </p>
                </div>
                <Link to={"/booking/" + rooms?.[0]?.id}>
                  <button>Book</button>
                </Link>
              </div>
            </div>
            <div className="accomodation__main__special__item">
              <img src={rooms?.[0]?.image} alt="" />

              <div className="accomodation__main__special__item__info">
                <div className="accomodation__main__special__item__info__left">
                  <Link to={"/room-details/" + rooms?.[0]?.id}>
                    <h4>{rooms?.[0]?.name}</h4>{" "}
                  </Link>
                  <div className="accomodation__main__special__item__info__left__line"></div>
                  <p>
                    {rooms?.[0]?.price?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}{" "}
                    <span>/ night</span>
                  </p>
                </div>
                <Link to={"/booking/" + rooms?.[0]?.id}>
                  <button>Book</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accomodation__main__header">
          <h2>OTHER DECENT ROOMS</h2>
          <img src={anchor} alt="" />
        </div>

        <div className="accomodation__main__filterCon">
          <Filter />
        </div>

        <div className="accomodation__main__other">
          {loading ? (
            <Loading />
          ) : (
            <div className="accomodation__main__other__rooms">
              {rooms?.map((room) => {
                return <RoomCard key={room.id} room={room} />;
              })}
            </div>
          )}

          {pageCount > 1 && (
            <div style={{ marginTop: 40 }}>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                breakLabel="..."
                //   renderOnZeroPageCount={null}
                onPageChange={handlePageClick}
                // pageRangeDisplayed={itemsPerPage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accomodation;
