import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + "/api";

export const fetchUsers = (page, token) => {
  return axios.get(`${apiURL}/user/all/${page}/10`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchDashboardCount = (token) => {
  return axios.get(`${apiURL}/count/dashboard`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchUsersBySearch = (query, token, page) => {
  return axios.get(`${apiURL}/user/search/${query}/${page}/10`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchMessage = (page, token) => {
  return axios.get(`${apiURL}/message/all/${page}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchBooking = (token, id) => {
  return axios.get(`${apiURL}/book/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchBookings = (page, token) => {
  return axios.get(`${apiURL}/book/all/${page}/10`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const fetchBookingsBySearch = (query, token, page) => {
  return axios.get(`${apiURL}/book/search/${query}/${page}/10`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const addRoom = (data, token) => {
  return axios.post(`${apiURL}/room/add`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const editRoom = (data, token, id) => {
  return axios.put(`${apiURL}/room/update/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const deleteRoom = (token, id) => {
  return axios.delete(`${apiURL}/room/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
