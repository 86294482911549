import React, { useContext } from "react";
import logoIcon from "../assets/image/logo.png";
import callIcon from "../assets/image/call.png";
import { Link, useLocation } from "react-router-dom";
import Drawer from "./Drawer";
import HoverMenu from "./HoverMenu";
import { Context } from "../context/MainContext";

export const covertToUrl = (name) => {
  if (name === "HOME") {
    return "";
  }

  return name.split(" ").join("-").toLowerCase();
};

const TopBar = () => {
  const { user } = useContext(Context);

  const { pathname } = useLocation();

  const links = [
    { name: "HOME" },
    { name: "ACCOMODATION" },
    { name: "GALLERY" },
    {
      name: "FEATURES",
      subLinks: [
        {
          name: "ABOUT US",
        },
        {
          name: "BOOKING",
        },
        {
          name: "ROOM DETAILS",
        },
        {
          name: "OUR STAFF",
        },
      ],
    },
    { name: "CONTACTS" },
  ];

  return (
    <nav className="topbar">
      <div className="topbar__left">
        <Link to="/">
          <img src={logoIcon} alt="" className="topbar__left__logo" />
        </Link>

        <div className="topbar__left__line"></div>
        <div className="topbar__left__auth">
          {!user?.id ? (
            <>
              <Link to="/login">
                <span>LOGIN</span>
              </Link>
              <Link to="/signup">
                <span>REGISTER</span>
              </Link>
            </>
          ) : (
            <Link to="/account">
              <span>ACCOUNT</span>
            </Link>
          )}
        </div>
      </div>

      <div className="topbar__right">
        <ul className="topbar__right__links">
          {links.map((link) => {
            return (
              <div key={link.name}>
                {link?.subLinks ? (
                  <HoverMenu links={link} covertToUrl={covertToUrl} />
                ) : (
                  <Link to={"/" + covertToUrl(link.name)}>
                    <li
                      className={
                        pathname === "/" + covertToUrl(link.name)
                          ? "topbar__right__links__item active"
                          : pathname === "/" && link.name === "HOME"
                          ? "topbar__right__links__item active"
                          : "topbar__right__links__item"
                      }
                    >
                      {link.name}
                    </li>
                  </Link>
                )}
              </div>
            );
          })}
        </ul>
        <Drawer links={links} />

        <div className="topbar__right__tel">
          <img src={callIcon} alt="" />{" "}
          <a href="tel:1234567890">123 456 7890</a>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
