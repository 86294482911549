import { useRef, useState } from "react";
import { ControlledMenu, MenuItem, useHover } from "@szhsin/react-menu";
import arrowdownIcon from "../assets/image/svg/arrowdown.svg";
import { Link, useLocation } from "react-router-dom";

const HoverMenu = ({ links, drawer, setModalIsOpen, covertToUrl }) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const { pathname } = useLocation();

  return (
    <>
      {/* <Link to={links.link}> */}
      <li
        className={
          drawer
            ? "drawer__modal__list__item"
            : pathname.includes("about") ||
              pathname.includes("staff") ||
              pathname.includes("booking")
            ? "topbar__right__links__item active"
            : "topbar__right__links__item"
        }
        ref={ref}
        {...anchorProps}
      >
        {links.name} <img src={arrowdownIcon} alt="" />
      </li>
      {/* </Link> */}

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        {links.subLinks.map((link, i) => {
          return (
            <Link
              key={i}
              to={
                link.name === "BOOKING" || link.name === "ROOM DETAILS"
                  ? "/" +
                    covertToUrl(link.name) +
                    "/b0ac27cd-76e9-4f70-bcdc-32453543556e"
                  : "/" + covertToUrl(link.name)
              }
            >
              <MenuItem onClick={() => drawer && setModalIsOpen(false)}>
                {link.name}
              </MenuItem>
            </Link>
          );
        })}
      </ControlledMenu>
    </>
  );
};

export default HoverMenu;
