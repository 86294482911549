import React, { useEffect } from "react";
import HeaderImg from "../components/HeaderImg";
import facility1 from "../assets/image/facility1.png";
import facility2 from "../assets/image/facility2.png";
import facility3 from "../assets/image/facility3.png";
import facility4 from "../assets/image/facility4.png";
import facility5 from "../assets/image/facility5.png";
import facility6 from "../assets/image/facility6.png";
import facility7 from "../assets/image/facility7.png";
import facility8 from "../assets/image/facility8.png";
import slider from "../assets/image/slider.jpg";
import { Link } from "react-router-dom";

export const facilities = [
  {
    name: "Breakfast",
    img: facility1,
    slug: "breakfast",
  },
  {
    name: "Air conditioning",
    img: facility2,
    slug: "airCondition",
  },
  {
    name: "Free Parking",
    img: facility3,
    slug: "freeParking",
  },
  {
    name: "Pet allowed",
    img: facility4,
    slug: "petAllowed",
  },
  {
    name: "Room service",
    img: facility5,
    slug: "roomService",
  },
  {
    name: "GYM fecility",
    img: facility6,
    slug: "gym",
  },
  {
    name: "TV LCD",
    img: facility7,
    slug: "tv",
  },
  {
    name: "Wi-fi service",
    img: facility8,
    slug: "wifi",
  },
];

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <HeaderImg title="About Us" alt={true} />

      <div className="about__main">
        <p>
          Semper ac dolor vitae msan. Cras interdum hendreritnia Phasellus
          accumsan rna vitae molestie interdum. Nam sed placerat libero, non
          eleifend dolor. Cras ac justo et augue suscipit euismod vel eget
          lectus. Proin vehicula nunc arcu, pulvinar accumsan nuroin vehicula
          nunc arcu, pulvinarlla porta vel. Vivamus malesuada vitae sem ac
          pellentesque.
        </p>

        <div className="about__main__fac">
          <h4>OUR ROOM FACILITIES</h4>

          <div className="about__main__fac__main">
            <div className="about__main__fac__list">
              {facilities.map((item) => {
                return (
                  <div className="about__main__fac__list__item" key={item.name}>
                    <img src={item.img} alt="" />
                    <p>{item.name}</p>
                  </div>
                );
              })}
            </div>

            <div className="about__main__fac__line"></div>

            <Link to="/booking/b0ac27cd-76e9-4f70-bcdc-32453543556e">
              <button className="btnAlt">BOOK ROOM</button>
            </Link>
          </div>
        </div>

        <img src={slider} alt="" style={{ width: "100%", marginBottom: 50 }} />

        <p>
          Semper ac dolor vitae msan. Cras interdum hendreritnia Phasellus
          accumsan rna vitae molestie interdum. Nam sed placerat libero, non
          eleifend dolor. Cras ac justo et augue suscipit euismod vel eget
          lectus. Proin vehicula nunc arcu, pulvinar accumsan nuroin vehicula
          nunc arcu, pulvinarlla porta vel. Vivamus malesuada vitae sem ac
          pellentesque.
        </p>
      </div>
    </div>
  );
};

export default About;
