import React from "react";
import { Label } from "./styledComponent/formInputs";

const RadioForm = ({ name, value, setValue }) => {
  const handleRadioChange = (event, setValue) => {
    event.currentTarget.value === "true" ? setValue(true) : setValue(false);
  };
  return (
    <div className="addProduct__form__item">
      <Label htmlFor="">{name}</Label>
      <br />
      <input
        type="radio"
        id={name + "true"}
        value="true"
        name={name}
        checked={value === true}
        onChange={(e) => handleRadioChange(e, setValue)}
      />{" "}
      <label htmlFor={name + "true"}>True</label>
      <input
        type="radio"
        id={name + "false"}
        value="false"
        name={name}
        checked={value === false}
        onChange={(e) => handleRadioChange(e, setValue)}
      />{" "}
      <label htmlFor={name + "false"}>False</label>
    </div>
  );
};

export default RadioForm;
