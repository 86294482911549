import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/styles/scss/main.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./pages/NotFound";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-slideshow-image/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Account from "./pages/Account";
import axios from "axios";
import { useContext } from "react";
import { Context } from "./context/MainContext";
import { userLogOut } from "./context/Action";
import DashboardLayout from "./layouts/DashboardLayout";
import HomeDB from "./pages/HomeDB";
import MessageDB from "./pages/MessageDB";
import VerifyMail from "./pages/VerifyMail";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Accomodation from "./pages/Accomodation";
import Gallary from "./pages/Gallary";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Ourstaff from "./pages/Ourstaff";
import Room from "./pages/Room";
import Booking from "./pages/Booking";
import BookingDB from "./pages/BookingDB";
import RoomsDB from "./pages/RoomsDB";
import AddRoom from "./pages/AddRoom";
import EditRoom from "./pages/EditRoom";
import BookItemDB from "./pages/BookItemDB";

function App() {
  const { user, dispatch } = useContext(Context);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (user && user?.id) {
          localStorage.removeItem("sokoUser");
          dispatch(userLogOut());
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      <ToastContainer position="top-right" />
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="accomodation" element={<Accomodation />} />
            <Route path="gallery" element={<Gallary />} />
            <Route path="contacts" element={<Contact />} />
            <Route path="about-us" element={<About />} />
            <Route path="our-staff" element={<Ourstaff />} />
            <Route path="room-details/:id" element={<Room />} />
            <Route path="booking/:id" element={<Booking />} />

            <Route path="login" element={<Signin />} />
            <Route path="signup" element={<Signup />} />
            <Route path="verify-mail/:token" element={<VerifyMail />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="account" element={<Account />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<HomeDB />} />
            <Route path="bookings" element={<BookingDB />} />
            <Route path="books/:id" element={<BookItemDB />} />
            <Route path="message" element={<MessageDB />} />
            <Route path="rooms" element={<RoomsDB />} />
            <Route path="rooms/add" element={<AddRoom />} />
            <Route path="rooms/edit/:id" element={<EditRoom />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
