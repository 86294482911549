import { useContext, useEffect, useState } from "react";
import {
  Input,
  Label,
  Textarea,
} from "../components/styledComponent/formInputs";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";
import { fetchRoom } from "../api/usersApi";
import RadioForm from "../components/RadioForm";
import { editRoom } from "../api/adminApi";

const EditRoom = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState("");
  const [capacity, setcapacity] = useState("");
  const [beds, setbeds] = useState("");
  const [roomNo, setroomNo] = useState("");
  const [breakfast, setbreakfast] = useState(false);
  const [airCondition, setairCondition] = useState(false);
  const [freeParking, setfreeParking] = useState(false);
  const [petAllowed, setpetAllowed] = useState(false);
  const [roomService, setroomService] = useState(false);
  const [gym, setgym] = useState(false);
  const [tv, settv] = useState(false);
  const [special, setspecial] = useState(false);
  const [wifi, setwifi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const { id } = useParams();

  const validate = () => {
    let nameError = "";
    let roomNoError = "";
    let priceError = "";
    let capacityError = "";
    let bedsError = "";

    if (!name) {
      nameError = "name is required, minimum 2 characters";
    }
    if (!roomNo) {
      roomNoError = "roomNo is required, minimum 2 characters";
    }
    if (!price) {
      priceError = "price is required";
    }
    if (!beds) {
      bedsError = "beds is required";
    }
    if (!capacity) {
      capacityError = "capacity is required";
    }

    if (nameError || roomNoError || priceError || bedsError || capacityError) {
      setInputError((curr) => {
        return {
          ...curr,
          name: nameError,
          price: priceError,
          roomNo: roomNoError,
          beds: bedsError,
          capacity: capacityError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate && user?.id) {
      setInputError({});
      setLoading(true);

      let data = {
        room: {
          price,
          roomNo,
          name,
          beds,
          special,
          capacity,
          description,
        },
        facility: {
          breakfast,
          airCondition,
          freeParking,
          petAllowed,
          roomService,
          gym,
          tv,
          wifi,
        },
      };

      try {
        await editRoom(data, user?.accessToken, id);

        setLoading(false);
        toast.success("Product Updated successfully");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchOrder = async () => {
      if (user?.id) {
        setLoadingMain(true);
        try {
          const res = await fetchRoom(id);

          setname(res.data.room.name);
          setdescription(res.data.room.description);
          setprice(res.data.room.price);
          setroomNo(res.data.room.roomNo);
          setbeds(res.data.room.beds);
          setcapacity(res.data.room.capacity);
          setspecial(res.data.room.special);
          setgym(res.data.facility.gym);
          setbreakfast(res.data.facility.breakfast);
          setairCondition(res.data.facility.airCondition);
          setfreeParking(res.data.facility.freeParking);
          setroomService(res.data.facility.roomService);
          setgym(res.data.facility.gym);
          settv(res.data.facility.tv);
          setwifi(res.data.facility.wifi);
          setpetAllowed(res.data.facility.petAllowed);

          setLoadingMain(false);
        } catch (err) {
          setLoadingMain(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.accessToken]);

  return (
    <div className="addProduct">
      <h2>Edit Room</h2>

      {loadingMain ? (
        <Loading />
      ) : (
        <>
          <div className="addProduct__form">
            <div className="addProduct__form__item">
              <Label htmlFor="name">
                Name <span>*</span>
              </Label>
              <Input
                id="name"
                type="text"
                error={inputError.name ? true : false}
                onChange={(e) => setname(e.target.value)}
                value={name}
              />
            </div>

            <div className="addProduct__form__item">
              <Label htmlFor="roomNo">
                Number of Rooms <span>*</span>
              </Label>
              <Input
                id="roomNo"
                type="number"
                error={inputError.roomNo ? true : false}
                onChange={(e) => setroomNo(e.target.value)}
                value={roomNo}
              />
            </div>

            <div className="addProduct__form__item">
              <Label htmlFor="beds">
                Number of Beds <span>*</span>
              </Label>
              <Input
                id="beds"
                type="number"
                error={inputError.beds ? true : false}
                onChange={(e) => setbeds(e.target.value)}
                value={beds}
              />
            </div>
            <div className="addProduct__form__item">
              <Label htmlFor="capacity">
                Capacity <span>*</span>
              </Label>
              <Input
                id="capacity"
                type="number"
                error={inputError.capacity ? true : false}
                onChange={(e) => setcapacity(e.target.value)}
                value={capacity}
              />
            </div>
            <div className="addProduct__form__item">
              <Label htmlFor="price">
                Price <span>*</span>
              </Label>
              <Input
                id="price"
                type="number"
                error={inputError.price ? true : false}
                onChange={(e) => setprice(e.target.value)}
                value={price}
              />
            </div>
            <div className="addProduct__form__item">
              <Label htmlFor="description">Description</Label>
              <Textarea
                rows={5}
                id="description"
                error={inputError.description ? true : false}
                onChange={(e) => setdescription(e.target.value)}
                value={description}
              />
            </div>
            {/* <br /> */}
          </div>
          <div className="addProduct__form__itemRadios">
            <RadioForm name="Special" value={special} setValue={setspecial} />
            <br />
            <RadioForm
              name="Breakfast"
              value={breakfast}
              setValue={setbreakfast}
            />
            <br />
            <RadioForm
              name="AirCondition"
              value={airCondition}
              setValue={setairCondition}
            />
            <br />
            <RadioForm
              name="FreeParking"
              value={freeParking}
              setValue={setfreeParking}
            />
            <br />
            <RadioForm
              name="PetAllowed"
              value={petAllowed}
              setValue={setpetAllowed}
            />
            <br />
            <RadioForm
              name="RoomService"
              value={roomService}
              setValue={setroomService}
            />
            <br />
            <RadioForm name="Gym" value={gym} setValue={setgym} />
            <br />
            <RadioForm name="Tv" value={tv} setValue={settv} />
            <br />
            <RadioForm name="Wifi" value={wifi} setValue={setwifi} />
            <br />
          </div>

          <div className="addProduct__btn">
            <button className="btn" onClick={handleSubmit}>
              {loading ? <Loading button={true} /> : "Edit"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditRoom;
