import HeaderImg from "../components/HeaderImg";
import staff from "../assets/image/staff.jpg";
import { useEffect } from "react";

const Ourstaff = () => {
  const staffs = [1, 2, 3, 4, 5, 6, 7, 8];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ourstaff">
      <HeaderImg title="OUR STAFF" alt={true} />

      <div className="ourstaff__main">
        <h3>OUR HOTEL STAFF</h3>

        <div className="ourstaff__main__list">
          {staffs.map((item) => {
            return (
              <div key={item} className="ourstaff__main__list__item">
                <div className="ourstaff__main__list__item__img">
                  <img src={staff} alt="" />
                </div>
                <div className="ourstaff__main__list__item__info">
                  <h4>DOHN DOE</h4>
                  <h5>Hotel Manager</h5>
                  <p>
                    Semper ac dolor vitae msan. Cras interdum hendreritnia
                    Phasellus accumsan rna.
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Ourstaff;
