import React, { useEffect, useState } from "react";
import HeaderImg from "../components/HeaderImg";
import {
  Input,
  Label,
  Textarea,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import { sendMessage } from "../api/usersApi";
import Loading from "../components/Loading";
import SocialLinks from "../components/SocialLinks";
import location from "../assets/image/svg/locationAlt.svg";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [inputError, setInputError] = useState({});

  const validate = () => {
    let nameError = "";
    let messageError = "";
    let emailError = "";

    if (name.length < 2) {
      nameError = "name is required, minimum 2 characters";
    }
    if (message.length < 2) {
      messageError = "message is required, minimum 2 characters";
    }
    if (email.length < 2) {
      emailError = "email is required, minimum 2 characters";
    }

    if (nameError || messageError || emailError) {
      setInputError((curr) => {
        return {
          ...curr,
          name: nameError,
          message: messageError,
          email: emailError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    setInputError({});

    if (checkValidate) {
      setLoading(true);
      try {
        const data = {
          name,
          message,
          email,
        };
        await sendMessage(data);

        setLoading(false);
        setName("");
        setEmail("");
        setMessage("");
        toast.success("Message sent successfully");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact">
      <HeaderImg title="Contact Us" />

      <div className="contact__mainCon">
        <div className="contact__main">
          <div className="contact__right">
            <h3>SEND US AN EMAIL</h3>

            <div className="">
              <Label htmlFor="name">
                {" "}
                Name <span>*</span>
              </Label>
              <Input
                id="name"
                value={name}
                type="text"
                error={inputError.name ? true : false}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="">
              <Label htmlFor="email">
                {" "}
                Email <span>*</span>
              </Label>
              <Input
                id="email"
                type="text"
                value={email}
                error={inputError.email ? true : false}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="">
              <Label htmlFor="message">
                {" "}
                Message <span>*</span>
              </Label>
              <Textarea
                name="message"
                id="message"
                rows="7"
                value={message}
                error={inputError.message ? true : false}
                onChange={(e) => setMessage(e.target.value)}
              ></Textarea>
            </div>

            <button className="contact__right__btn" onClick={handleSubmit}>
              {loading ? <Loading button={true} /> : "Send"}
            </button>
          </div>

          <div className="contact__left">
            <h3 style={{ marginTop: 55 }}>CONTACT INFO</h3>
            <p>
              <img src={location} alt="" style={{ width: 24 }} />
              St Amsterdam finland, <br />
              United Stats of AKY16 8PN
            </p>
            <br />

            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "gray" }}
              >
                <path d="m20.487 17.14-4.065-3.696a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a.997.997 0 0 0-.085-1.39z"></path>
              </svg>
              1234567890
            </p>
            <br />

            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "gray" }}
              >
                <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path>
              </svg>
              info@hotelbooking.com
            </p>
            <br />

            <SocialLinks color="gray" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
