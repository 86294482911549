import { Link } from "react-router-dom";
import slider from "../assets/image/slider.jpg";
import { Fade } from "react-slideshow-image";
import BookFloat from "./BookFloat";

const Hero = () => {
  const fadeImages = [slider, slider, slider];

  return (
    <section className="hero">
      <Fade
        autoplay={true}
        infinite={true}
        pauseOnHover={false}
        duration={5000}
        arrows={false}
      >
        <div className="hero__eachFade">
          <img src={fadeImages[0]} alt="Hero 1" />
        </div>
        <div className="hero__eachFade">
          <img src={fadeImages[1]} alt="Hero 2" />
        </div>
        <div className="hero__eachFade">
          <img src={fadeImages[2]} alt="Hero 3" />
        </div>
      </Fade>

      <div className="hero__main">
        <h1>
          A BRAND NEW HOTEL <br />
          BEYOND ORDINARY
        </h1>
        <div className="hero__main__buttons">
          <Link to="/accomodation">
            <button className="btn">
              <span>Explore It</span>
            </button>
          </Link>
        </div>
      </div>
      <BookFloat />
    </section>
  );
};

export default Hero;
