import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

const BookFloatAlt = ({ id }) => {
  const [arrival, setArrival] = useState("");
  const [departure, setDeparture] = useState("");

  const roomNo = [1, 2, 3];
  const personsNo = [1, 2, 3, 4, 5];
  const childNo = [0, 1, 2, 3, 4, 5];

  return (
    <div className="bookFloatAlt">
      <h3 className="bookFloatAlt__title">
        <span>BOOK YOUR</span> <br /> Rooms
      </h3>
      <div className="bookFloatAlt__form">
        <div className="bookFloatAlt__form__inputCon">
          <DatePicker
            minDate={new Date()}
            showIcon
            selected={arrival}
            onChange={(date) => setArrival(date)}
            className="bookFloatAlt__form__inputCon__dateInput"
            placeholderText="ARRIVAL"
          />
        </div>
        <div className="bookFloatAlt__form__inputCon">
          <DatePicker
            minDate={new Date()}
            showIcon
            selected={departure}
            onChange={(date) => setDeparture(date)}
            className="bookFloatAlt__form__inputCon__dateInput"
            placeholderText="DEPARTURE"
          />
        </div>
        <div className="bookFloatAlt__form__inputCon">
          <select name="room" id="room">
            {roomNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ROOM
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloatAlt__form__inputCon">
          <select name="adult" id="adult">
            {personsNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ADULT
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloatAlt__form__inputCon">
          <select name="child" id="child">
            {childNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ADULT
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloatAlt__form__inputCon">
          <Link to={"/booking/" + id}>
            <button className="bookFloatAlt__form__inputCon__button">
              BOOK
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookFloatAlt;
