import React from "react";
import anchor from "../assets/image/anchor.png";

const HeaderImg = ({ title, alt }) => {
  return (
    <div className={!alt ? "headerImg" : "headerImg alt"}>
      <h2>{title}</h2>
      <img src={anchor} alt="" />
    </div>
  );
};

export default HeaderImg;
