import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { fetchBooking } from "../api/adminApi";
import Loading from "../components/Loading";

const BookItemDB = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);

  const formattingOption = {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  };

  const { id } = useParams();

  useEffect(() => {
    const handleFetchOrder = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchBooking(user?.accessToken, id);

          setOrder(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.accessToken]);

  return (
    <div className="orderItemDB">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="checkout__right">
            <h2>Booking Item</h2>

            <div className="cart__main__total" style={{ marginTop: 30 }}>
              <div className="cart__main__total__part">
                <b>Date</b>
                {order?.createdAt?.slice(0, 10)}
              </div>
              <div className="cart__main__total__part">
                <b>Name</b>
                {order?.firstName} {order?.lastName}
              </div>
              <div className="cart__main__total__part">
                <b>Email</b>
                {order?.email}
              </div>
              <div className="cart__main__total__part">
                <b>Phone</b>
                {order?.phone}
              </div>
              <div className="cart__main__total__part">
                <b>Arrival</b>
                {order?.arrival}
              </div>
              <div className="cart__main__total__part">
                <b>Departure</b>
                {order?.departure}
              </div>
              <div className="cart__main__total__part">
                <b>Number of days (Nights)</b>
                {order?.night}
              </div>
              <div className="cart__main__total__part">
                <b>Number Adults</b>
                {order?.adult}
              </div>
              <div className="cart__main__total__part">
                <b>Number Children</b>
                {order?.child}
              </div>
              <div className="cart__main__total__part">
                <b>Room Name</b>
                {order?.room?.name}
              </div>
              <div className="cart__main__total__part">
                <b>Room Id</b>
                {order?.room?.id}
              </div>
              <div className="cart__main__total__part">
                <b>Number of rooms</b>
                {order?.roomNo}
              </div>

              <div className="cart__main__total__part">
                <b>
                  Payment Id <br /> (Paystack Reference Number)
                </b>
                {order?.paymentId}
              </div>
              <div className="cart__main__total__part">
                <b>Payed</b>
                {order?.payed ? "True" : "False"}
              </div>
              <div className="cart__main__total__part">
                <b>User Id</b>
                {order?.userId}
              </div>
              <div className="cart__main__total__part">
                <b>Request</b>
                {order?.request}
              </div>

              <div className="cart__main__total__part">
                <b>Total</b>
                <b>
                  {order?.totalAmount?.toLocaleString(
                    "en-NG",
                    formattingOption
                  )}
                </b>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookItemDB;
