import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/MainContext";
import { updateBooking } from "../context/Action";

const BookFloat = () => {
  const [arrival, setArrival] = useState("");
  const [departure, setDeparture] = useState("");
  const [rooms, setRooms] = useState(1);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const { dispatch } = useContext(Context);
  //
  const roomNo = [1, 2, 3];
  const personsNo = [1, 2, 3, 4, 5];
  const childNo = [0, 1, 2, 3, 4, 5];

  const navigate = useNavigate();

  const handleBook = () => {
    // console.log(
    //   moment(arrival).format("MMM Do YYYY"),
    //   moment(departure).format("MMM Do YYYY"),
    //   rooms,
    //   children,
    //   adults
    // );

    const booking = {
      arrival,
      departure,
      adults,
      rooms,
      children,
    };

    dispatch(updateBooking(booking));

    navigate("/booking/b0ac27cd-76e9-4f70-bcdc-32453543556e");
  };

  return (
    <div className="bookFloat">
      <h3 className="bookFloat__title">
        <span>BOOK YOUR</span> Rooms
      </h3>
      <div className="bookFloat__line"></div>
      <div className="bookFloat__form">
        <div className="bookFloat__form__inputCon">
          <DatePicker
            dateFormat="dd/MM/YYYY"
            minDate={new Date()}
            showIcon
            selected={arrival}
            onChange={(date) => setArrival(date)}
            className="bookFloat__form__inputCon__dateInput"
            placeholderText="ARRIVAL"
          />
        </div>
        <div className="bookFloat__form__inputCon">
          <DatePicker
            minDate={new Date()}
            showIcon
            selected={departure}
            onChange={(date) => setDeparture(date)}
            className="bookFloat__form__inputCon__dateInput"
            placeholderText="DEPARTURE"
          />
        </div>
        <div className="bookFloat__form__inputCon">
          <select
            name="room"
            id="room"
            value={rooms}
            onChange={(e) => setRooms(e.target.value)}
          >
            {roomNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ROOM
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <select
            name="adult"
            id="adult"
            value={adults}
            onChange={(e) => setAdults(e.target.value)}
          >
            {personsNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ADULT
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <select
            name="child"
            id="child"
            value={children}
            onChange={(e) => setChildren(e.target.value)}
          >
            {childNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ADULT
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <button
            className="bookFloat__form__inputCon__button"
            onClick={handleBook}
          >
            BOOK
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookFloat;
