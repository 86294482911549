import anchor from "../assets/image/anchor.png";
import { Slide } from "react-slideshow-image";
import slider from "../assets/image/slider.jpg";

const ShowCase = () => {
  const fadeImages = [
    slider,
    slider,
    slider,
    slider,
    slider,
    slider,
    slider,
    slider,
    slider,
  ];
  return (
    <div className="showCase">
      <div className="showCase__header">
        <h3>HOTEL SHOWCASE</h3>
        <img src={anchor} alt="" />
      </div>

      <div className="showCase__main">
        <Slide
          responsive={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 6,
              },
            },
          ]}
          slidesToScroll={1}
          slidesToShow={2}
          arrows={false}
          infinite={true}
          pauseOnHover={false}
          duration={3000}
          indicators={false}
        >
          {fadeImages.map((item, i) => {
            return (
              <div key={i} className="showCase__main__item">
                <img src={item} alt="" />
              </div>
            );
          })}
        </Slide>
      </div>
    </div>
  );
};

export default ShowCase;
