import { useContext, useState } from "react";
import {
  ErrorMessage,
  Input,
  Label,
  Textarea,
} from "../components/styledComponent/formInputs";
import { Context } from "../context/MainContext";
import { addRoom } from "../api/adminApi";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import RadioForm from "../components/RadioForm";

const AddRoom = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [imgs, setImgs] = useState([]);
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState("");
  const [capacity, setcapacity] = useState("");
  const [beds, setbeds] = useState("");
  const [roomNo, setroomNo] = useState("");
  const [breakfast, setbreakfast] = useState(false);
  const [airCondition, setairCondition] = useState(false);
  const [freeParking, setfreeParking] = useState(false);
  const [petAllowed, setpetAllowed] = useState(false);
  const [roomService, setroomService] = useState(false);
  const [gym, setgym] = useState(false);
  const [tv, settv] = useState(false);
  const [special, setspecial] = useState(false);
  const [wifi, setwifi] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let nameError = "";
    let roomNoError = "";
    let priceError = "";
    let capacityError = "";
    let bedsError = "";
    let imgError = "";

    if (!name) {
      nameError = "name is required, minimum 2 characters";
    }
    if (!roomNo) {
      roomNoError = "roomNo is required, minimum 2 characters";
    }
    if (!price) {
      priceError = "price is required";
    }
    if (!beds) {
      bedsError = "beds is required";
    }
    if (!capacity) {
      capacityError = "capacity is required";
    }
    if (imgs.length < 1) {
      imgError = "Image is required";
    }

    if (
      nameError ||
      roomNoError ||
      priceError ||
      bedsError ||
      capacityError ||
      imgError
    ) {
      setInputError((curr) => {
        return {
          ...curr,
          name: nameError,
          price: priceError,
          roomNo: roomNoError,
          beds: bedsError,
          capacity: capacityError,
          img: imgError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate && user?.id) {
      setInputError({});
      setLoading(true);

      let data = {
        room: {
          price,
          roomNo,
          name,
          beds,
          special,
          image: imgs[0]?.img,
          capacity,
          description,
        },
        gallary: imgs,
        facility: {
          breakfast,
          airCondition,
          freeParking,
          petAllowed,
          roomService,
          gym,
          tv,
          wifi,
        },
      };

      try {
        await addRoom(data, user?.accessToken);

        setLoading(false);
        toast.success("Room Added successfully");
        setname("");
        setImgs([]);
        setdescription("");
        setprice("");
        setroomNo("");
        setbeds("");
        setcapacity("");
        setgym(false);
        setbreakfast(false);
        setairCondition(false);
        setfreeParking(false);
        setroomService(false);
        setgym(false);
        settv(false);
        setwifi(false);
        setspecial(false);
        setpetAllowed(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length === 1) {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);

      setImgs((curr) => [...curr, { id: curr.length + 1, img: base64 }]);
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const base64 = await convertToBase64(file);
        setImgs((curr) => [...curr, { id: curr.length + 1, img: base64 }]);
      }
    }
  };

  const handleRomoveImg = (id) => {
    const newImg = imgs.filter((item) => item.id !== id);

    setImgs(newImg);
  };

  return (
    <div className="addProduct">
      <h2>Add Room</h2>

      <div className="addProduct__form__item">
        <Label htmlFor="street">
          Image <span>*</span>
        </Label>
        <br />
        <input type="file" onChange={(e) => handleFileUpload(e)} multiple />
        <ErrorMessage show={inputError.roomNo ? true : false}>
          {inputError?.img}
        </ErrorMessage>
      </div>
      <div className="addProduct__form__itemImgs">
        {imgs?.map((img) => {
          return (
            <div key={img.id} className="addProduct__form__itemImgs__main">
              <img src={img.img} alt="" />
              <span onClick={() => handleRomoveImg(img.id)}>x</span>
            </div>
          );
        })}
      </div>
      <div className="addProduct__form">
        <div className="addProduct__form__item">
          <Label htmlFor="name">
            Name <span>*</span>
          </Label>
          <Input
            id="name"
            type="text"
            error={inputError.name ? true : false}
            onChange={(e) => setname(e.target.value)}
            value={name}
          />
        </div>

        <div className="addProduct__form__item">
          <Label htmlFor="roomNo">
            Number of Rooms <span>*</span>
          </Label>
          <Input
            id="roomNo"
            type="number"
            error={inputError.roomNo ? true : false}
            onChange={(e) => setroomNo(e.target.value)}
            value={roomNo}
          />
        </div>

        <div className="addProduct__form__item">
          <Label htmlFor="beds">
            Number of Beds <span>*</span>
          </Label>
          <Input
            id="beds"
            type="number"
            error={inputError.beds ? true : false}
            onChange={(e) => setbeds(e.target.value)}
            value={beds}
          />
        </div>
        <div className="addProduct__form__item">
          <Label htmlFor="capacity">
            Capacity <span>*</span>
          </Label>
          <Input
            id="capacity"
            type="number"
            error={inputError.capacity ? true : false}
            onChange={(e) => setcapacity(e.target.value)}
            value={capacity}
          />
        </div>
        <div className="addProduct__form__item">
          <Label htmlFor="price">
            Price <span>*</span>
          </Label>
          <Input
            id="price"
            type="number"
            error={inputError.price ? true : false}
            onChange={(e) => setprice(e.target.value)}
            value={price}
          />
        </div>
        <div className="addProduct__form__item">
          <Label htmlFor="description">Description</Label>
          <Textarea
            rows={5}
            id="description"
            error={inputError.description ? true : false}
            onChange={(e) => setdescription(e.target.value)}
            value={description}
          />
        </div>
        {/* <br /> */}
      </div>
      <div className="addProduct__form__itemRadios">
        <RadioForm name="Special" value={special} setValue={setspecial} />
        <br />
        <RadioForm name="Breakfast" value={breakfast} setValue={setbreakfast} />
        <br />
        <RadioForm
          name="AirCondition"
          value={airCondition}
          setValue={setairCondition}
        />
        <br />
        <RadioForm
          name="FreeParking"
          value={freeParking}
          setValue={setfreeParking}
        />
        <br />
        <RadioForm
          name="PetAllowed"
          value={petAllowed}
          setValue={setpetAllowed}
        />
        <br />
        <RadioForm
          name="RoomService"
          value={roomService}
          setValue={setroomService}
        />
        <br />
        <RadioForm name="Gym" value={gym} setValue={setgym} />
        <br />
        <RadioForm name="Tv" value={tv} setValue={settv} />
        <br />
        <RadioForm name="Wifi" value={wifi} setValue={setwifi} />
        <br />
      </div>

      <div className="addProduct__btn">
        <button className="btn" onClick={handleSubmit}>
          {loading ? <Loading button={true} /> : "Add"}
        </button>
      </div>
    </div>
  );
};

export default AddRoom;
