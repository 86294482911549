import { Slide } from "react-slideshow-image";
import HeaderImg from "../components/HeaderImg";
import { facilities } from "./About";
import RoomCard, { formattingOption } from "../components/RoomCard";
import BookFloatAlt from "../components/BookFloatAlt";
import { useEffect, useState } from "react";
import { fetchRoom, fetchRooms } from "../api/usersApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";

const Room = () => {
  const [room, setRoom] = useState({});
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const handleFetchRoom = async () => {
      setLoading(true);
      try {
        const res = await fetchRoom(id);

        setRoom(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchRoom();

    const handleFetchRooms = async () => {
      setMainLoading(true);
      try {
        const res = await fetchRooms(1, 5);

        const newRooms = res.data?.rooms
          ?.filter((room) => room.id !== id)
          .slice(0, 3);
        setRooms(newRooms);
        setMainLoading(false);
      } catch (err) {
        setMainLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <div className="room">
      <HeaderImg title={room?.room?.name} />

      {loading ? (
        <Loading />
      ) : room?.gallary?.length > 0 ? (
        <div className="room__main">
          <div className="room__main__top">
            <div className="room__main__top__header">
              <h3 style={{ textTransform: "uppercase" }}>
                {room?.room?.name} DETAIL
              </h3>

              <p>
                {room?.room?.price?.toLocaleString("en-NG", formattingOption)}{" "}
                <span>/ night</span>
              </p>
            </div>

            <div className="room__main__top__images">
              <Slide
                slidesToScroll={1}
                slidesToShow={1}
                arrows={true}
                infinite={true}
                pauseOnHover={false}
                duration={3000}
                indicators={true}
              >
                {room?.gallary?.map((item, i) => {
                  return (
                    <div key={i} className="gallary__main__special__left__item">
                      <img src={item?.image} alt="" />
                    </div>
                  );
                })}
              </Slide>
            </div>
          </div>

          <div className="room__main__bottom">
            <div className="room__main__bottom__left">
              <div className="room__main__bottom__left__fac">
                <h4>ROOM FACILITIES</h4>

                <div className="room__main__bottom__left__fac__list">
                  {facilities.map((item) => {
                    if (room?.facility?.[item.slug]) {
                      return (
                        <div
                          className="room__main__bottom__left__fac__list__item"
                          key={item.name}
                        >
                          <img src={item.img} alt="" />
                          <p>{item.name}</p>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>

              <p className="room__main__bottom__left__desc">
                {room?.room?.description}
              </p>

              <div className="room__main__bottom__left__like">
                <h4 className="room__main__bottom__left__like__title">
                  YOU MAY ALSO LIKE
                </h4>

                {mainLoading ? (
                  <Loading />
                ) : (
                  <div className="room__main__bottom__left__like__list">
                    {rooms.map((item) => {
                      return <RoomCard room={item} key={item.id} />;
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="room__main__bottom__right">
              <BookFloatAlt id={room.room.id} />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Room;
