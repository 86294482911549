import React, { useState } from "react";
import { formattingOption } from "./RoomCard";

const Filter = () => {
  const [price, setPrice] = useState("5000");

  const roomNo = [1, 2, 3];
  const bedNo = [1, 2, 3, 4, 5];

  return (
    <div
      className="bookFloat"
      style={{ position: "static", transform: "none", display: "inline-grid" }}
    >
      <h3 className="bookFloat__title">Filter Rooms</h3>
      <div className="bookFloat__line"></div>
      <div className="bookFloat__form">
        <div className="bookFloat__form__inputCon">
          <select name="room" id="room">
            {roomNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} ROOM
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <select name="bed" id="bed">
            {bedNo.map((no) => {
              return (
                <option key={no} value={no}>
                  {no} BEDS
                </option>
              );
            })}
          </select>
        </div>
        <div className="bookFloat__form__inputCon">
          <label htmlFor="price">PRICE: RANGE (₦5,000 - ₦100,000):</label>{" "}
          <div className="bookFloat__form__inputCon__range">
            <input
              type="range"
              id="price"
              name="price"
              min="5000"
              max="100000"
              step="1000"
              onChange={(e) => setPrice(e.target.value)}
            />
            <span>
              {parseInt(price).toLocaleString("en-NG", formattingOption)}
            </span>
          </div>
        </div>
        <div className="bookFloat__form__inputCon">
          <button className="bookFloat__form__inputCon__button">FILTER</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
